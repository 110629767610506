import type { ConfigurationItem, Translate } from '../types';

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export const settingsDropdown: ConfigurationItem = (t: Translate) => {
  return [
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'SettingsDropdownItem',
              {
                link: {
                  key: 'brandManagerEditor',
                  href: '/brandmanager',
                  title: t('VERTICAL_NAVIGATION_HEADER_BRAND_MANAGER_EDITOR'),
                  tracking: {
                    sc_o: 'navigation_manage_company_profile',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
          ],
        },
        flags: 'isBrandManagerEditor',
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'SettingsDropdownItem',
              {
                link: {
                  key: 'jobsPosterPersonal',
                  href: '/jobmanager/online',
                  title: t('VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS'),
                  tracking: {
                    sc_o: 'navigation_manage_jobs_ads',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
          ],
        },
        flags: 'isJobsPoster',
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'SettingsDropdownItem',
              {
                link: {
                  key: 'coachProfilePersonal',
                  href: 'https://coaches.xing.com/coaches/meinprofil',
                  title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_COACH_PROFILE'),
                  tracking: {
                    sc_o: 'navigation_coach_profile',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
          ],
        },
        flags: 'hasCoachProfile',
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'SettingsDropdownItem',
              {
                link: {
                  key: 'xtmPersonal',
                  href: '/xtm',
                  title: t('VERTICAL_NAVIGATION_HEADER_XTM'),
                  tracking: {
                    sc_o: 'navigation_talent_manager',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
          ],
        },
        flags: 'isCraUser',
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'SettingsDropdownItem',
              {
                link: {
                  key: 'seatManagerAdminPersonal',
                  href: '/seat-manager',
                  title: t('VERTICAL_NAVIGATION_HEADER_SEAT_MANAGER_ADMIN'),
                  tracking: {
                    sc_o: 'navigation_licence_manager',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
          ],
        },
        flags: 'isSeatManagerAdmin',
      },
    ],
    [
      'SettingsDropdownItem',
      {
        link: {
          key: 'invoicesPersonal',
          href: '/settings/purchases',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES'),
          tracking: {
            sc_o: 'navigation_memberships_invoices',
            sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
          },
        },
      },
    ],
    [
      'SettingsDropdownItem',
      {
        link: {
          key: 'settingsPersonal',
          href: '/settings',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS'),
          tracking: {
            sc_o: 'navigation_settings',
            sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
          },
        },
      },
    ],
    [
      'SettingsDropdownItem',
      {
        link: {
          key: 'helpPersonal',
          href: '/help',
          title: t('VERTICAL_NAVIGATION_HEADER_HELP'),
          tracking: {
            sc_o: 'navigation_help',
            sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
          },
        },
      },
    ],
    [
      'SettingsDropdownItem',
      {
        link: {
          key: 'logoutPersonal',
          href: '/login/logout',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT'),
          tracking: {
            sc_o: 'navigation_logout',
            sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
          },
        },
      },
    ],
  ];
};
